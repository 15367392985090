import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
export const roleGuard: CanActivateFn = async (route) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const user = authService.$userSignal();
  const roles = route.data['roles'] as string[];
  if (user && !roles.includes(user.role)) {
    console.warn(
      'User is not authorized for this route, redirecting to login',
      router.url,
    );
    await router.navigate(['/login']);
    return false;
  }
  return true;
};
